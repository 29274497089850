import { createSlice } from '@reduxjs/toolkit';
import * as Actions from './actions';
import { addCaseWithLoading } from '../../helper';

const initialState = {
  isLoading: false,
  error: null,
  allJobs: null,
  postedJobs: null,
  jobWithApplicants: null
};

const jobSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    addCaseWithLoading(builder, Actions.createJob, {
      onCompleted: (state, action) => {
        state.isLoading = false;
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error
      },
    });
    addCaseWithLoading(builder, Actions.getAllJobs, {
      onCompleted: (state, action) => {
        console.log(action)
        state.isLoading = false;
        state.allJobs = action.payload.data
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error
      },
    });
    addCaseWithLoading(builder, Actions.getPostedJobs, {
      onCompleted: (state, action) => {
        console.log(action)
        state.isLoading = false;
        state.postedJobs = action.payload.data
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error
      },
    });
    addCaseWithLoading(builder, Actions.getSingleJobWithApplicants, {
      onCompleted: (state, action) => {
        console.log(action)
        state.isLoading = false;
        state.jobWithApplicants = action.payload.data
      },
      onPending: (state) => {
        state.isLoading = true;
        state.error = null;
      },
      onReject: (state, error) => {
        state.isLoading = false;
        state.error = error?.error
      },
    });
  },
});

export default jobSlice.reducer;