import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleJobWithApplicants } from '../../../redux/jobSlice/actions'
import { Badge, Table } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { hire } from '../../../redux/applicationSlice/actions'

function Applicants() {

    let dispatch = useDispatch()

    let user = useSelector((state) => state.auth.user)
    let job = useSelector((state) => state.job.jobWithApplicants)

    useEffect(() => {
        if (user) {
            let jobId = window.location.href.split('/')[window.location.href.split('/').length - 1]
            dispatch(getSingleJobWithApplicants(jobId)).then((res) => {
                if (res?.payload?.status === "success") {
                    // console.log(res.payload)
                }
            })
        }
    }, [user])


    function getDateAndTime(dateTimeString) {
        const dateTime = new Date(dateTimeString);

        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();

        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        const seconds = dateTime.getSeconds();
        const milliseconds = dateTime.getMilliseconds();

        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return { date: formattedDate, time: formattedTime };
    }

    const hireFreelancer = (id) => {
        let obj = {
            status: "inProgress"
        }

        dispatch(hire({ data: obj, id: id, jobId: job._id })).then((res) => {
            if (res?.payload?.status === "success") {
                let jobId = window.location.href.split('/')[window.location.href.split('/').length - 1]
                dispatch(getSingleJobWithApplicants(jobId))
            }
        })
    }

    return (
        <div className='applicantsMain shadow p-3 jobHistoryMain'>
            <div className='heading'>
                <h5>Applicants for - {job?.title}</h5>
                <h6>Status: {job?.status}</h6>
            </div>
            <div className='tableDiv'>
                <Table bordered hover>
                    <thead>
                        <tr>
                            <th>S. no</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Rate</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>More</th>
                        </tr>
                    </thead>
                    <tbody>
                        {job?.applications?.map((a, i) => (
                            <tr key={i} className={job?.status === "hired" && a?.status !== "inProgress" ? `dim` : ""}>
                                <td className='text-center'>{i + 1}</td>
                                <td>
                                    <div className='imgDiv' style={a?.applicant?.image ? { backgroundImage: `url(${a?.applicant?.image})` } : {}}></div>
                                </td>
                                <td>{a?.applicant?.name}</td>
                                <td>${a.amount}</td>
                                <td>{a.description}</td>
                                <td className='text-center'>{getDateAndTime(a.createdAt).date}<br />{getDateAndTime(a.createdAt).time}</td>
                                <td className='text-center'>
                                    <div className='btn-div'>
                                        {a?.status === "inProgress" ?
                                            <button className='primary' disabled>Hired</button>
                                            :
                                            job?.status === "hired" ?
                                                <button className='secondary danger'>Rejected</button>
                                                :
                                                <button className='primary' onClick={() => hireFreelancer(a?._id)}>Hire</button>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default Applicants