import React, { useEffect } from 'react'
import { Badge, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJobs, getPostedJobs } from '../../../redux/jobSlice/actions'
import { getAllApplications } from '../../../redux/applicationSlice/actions'
import { NavLink } from 'react-router-dom'

function JobHistory() {

  let dispatch = useDispatch()
  let postedJobs = useSelector((state) => state.job.postedJobs)
  let allApplications = useSelector((state) => state.applications.allApplications)
  let user = useSelector((state) => state.auth.user)

  useEffect(() => {
    if (user) {
      if (user?.role === "loved-one") {
        dispatch(getPostedJobs()).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        })
      }
      else {
        dispatch(getAllApplications({})).then((res) => {
          if (res?.payload?.status === "success") {
            // console.log(res.payload)
          }
        })
      }
    }
  }, [user])

  // useEffect(()=>{
  //   console.log(allApplications)
  // },[allApplications])

  return (
    <div className='jobHistoryMain p-3'>
      <form>
        <div className='searchInput'>
          <input type='text' placeholder='Search here ...' />
          <div className='searchIconBtn'>
            <button type='submit'>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
              </svg>
            </button>
          </div>
        </div>
      </form>
      <div className='tableDiv'>
        <Table bordered hover>
          <thead>
            {user?.role === 'loved-one' ?
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Rate</th>
                <th>Total</th>
                <th>Status</th>
                <th>Applicants</th>
                <th>Date</th>
                <th>More</th>
              </tr>
              :
              <tr>
                <th>S. no</th>
                <th>Job Title</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            }
          </thead>
          <tbody>
            {user?.role === "loved-one" ? postedJobs?.map((a, i) => (
              <tr key={i}>
                <td className='text-center'>{i + 1}</td>
                <td>{a.title}</td>
                <td>${a.budget}</td>
                <td>${a.budget}</td>
                <td className='text-center'>
                  {a.status === "approved" ?
                    <Badge pill bg="primary">
                      Approved
                    </Badge>
                    :
                    a.status === "completed" ?
                      <Badge pill bg="success">
                        Completed
                      </Badge>
                      :
                      a.status === "rejected" ?
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                        :
                        a.status === "hired" ?
                          <Badge pill bg="info">
                            Hired
                          </Badge>
                          :
                          <Badge pill bg="secondary">
                            Pending
                          </Badge>
                  }
                </td>
                <td className='text-center'><NavLink to={`/user/job/applicants/${a._id}`}>{a.applicationCount}</NavLink></td>
                <td>{a.createdAt.split("T")[0]}</td>
                <td className='text-center'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                  </svg>
                </td>
              </tr>
            ))
              :
              allApplications?.map((a, i) => (
                <tr key={i}>
                  <td className='text-center'>{i + 1}</td>
                  <td>{a.job.title}</td>
                  <td>${a.amount}</td>
                  <td className='text-center'>
                    {a.status === "applied" ?
                      <Badge pill bg="primary">
                        Applied
                      </Badge>
                      :
                      a.status === "rejected" ?
                        <Badge pill bg="danger">
                          Rejected
                        </Badge>
                        :
                        a.status === "inProgress" ?
                          <Badge pill bg="secondary">
                            In Progress
                          </Badge>
                          :
                          <Badge pill bg="success">
                            Finished
                          </Badge>
                    }
                  </td>
                  <td>{a.createdAt.split("T")[0]}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default JobHistory