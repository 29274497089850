import React, { useEffect } from 'react'
import { Badge, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJobs } from '../../../redux/jobSlice/actions'
import { getAllApplications } from '../../../redux/applicationSlice/actions'

function AppliedJobs() {

    let dispatch = useDispatch()
    let allApplications = useSelector((state) => state.applications.allApplications)

    useEffect(() => {
        dispatch(getAllApplications({ status: "applied" })).then((res) => {
            if (res?.payload?.status === "success") {
                // console.log(res.payload)
            }
        })
    }, [])

    return (
        <div className='jobHistoryMain p-3'>
            <form>
                <div className='searchInput'>
                    <input type='text' placeholder='Search here ...' />
                    <div className='searchIconBtn'>
                        <button type='submit'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#fff" class="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                            </svg>
                        </button>
                    </div>
                </div>
            </form>
            <div className='tableDiv'>
                <Table bordered hover>
                    <thead>
                        {
                            <tr>
                                <th>S. no</th>
                                <th>Job Title</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                            </tr>
                        }
                    </thead>
                    <tbody>
                        {allApplications?.map((a, i) => (
                            <tr key={i}>
                                <td className='text-center'>{i + 1}</td>
                                <td>{a.job.title}</td>
                                <td>${a.amount}</td>
                                <td className='text-center'>
                                    {a.status === "applied" ?
                                        <Badge pill bg="primary">
                                            Applied
                                        </Badge>
                                        :
                                        a.status === "rejected" ?
                                            <Badge pill bg="danger">
                                                Rejected
                                            </Badge>
                                            :
                                            a.status === "inProgress" ?
                                                <Badge pill bg="secondary">
                                                    In Progress
                                                </Badge>
                                                :
                                                <Badge pill bg="success">
                                                    Finished
                                                </Badge>
                                    }
                                </td>
                                <td>{a.createdAt.split("T")[0]}</td>
                            </tr>
                        ))
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default AppliedJobs