import logo from './logo.svg';
import {
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Common/Common.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Home from './Screens/Home/Home';
import Header from './Common/Header/Header';
import Footer from './Common/Footer/Footer';
import Jobs from './Screens/Jobs/Jobs';
import Faqs from './Screens/faqs/faqs';
import Blogs from './Screens/Blogs/Blogs';
import Contact from './Screens/Contact/Contact';
import Layout from './Layout';
import Login from './Screens/AuthScreens/Login/Login';
import Register from './Screens/AuthScreens/Register/Register';
import DashboardLayout from './DashboardLayout';
import Dashboard from './Screens/Portal/Dashboard/Dashboard';
import ForgotPass from './Screens/AuthScreens/ForgotPass/ForgotPass';
import ResetPass from './Screens/AuthScreens/ResetPass/ResetPass';
import { ToastContainer } from 'react-toastify';

import './Screens/Portal/Portal.scss'
import Profile from './Screens/Portal/Profile/Profile';
import CreateJob from './Screens/Portal/Jobs/CreateJob';
import JobHistory from './Screens/Portal/Jobs/JobHistory';
import FinishedJobs from './Screens/Portal/Jobs/FinishedJobs';
import AppliedJobs from './Screens/Portal/Jobs/AppliedJobs';
import Applicants from './Screens/Portal/Jobs/Applicants';
import JobsInProgress from './Screens/Portal/Jobs/JobInProgress';

function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Routes>
          {/* Home Page Route */}
          <Route path='/' element={<Layout><Home /></Layout>} />
          {/* Home Page Route End */}

          {/* Auth Routes */}
          <Route path='/login' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/forgot-password' element={<ForgotPass />} />
          <Route path='/reset-password' element={<ResetPass />} />
          {/* Auth Routes End */}

          {/* Other Routes */}
          <Route path='/jobs' element={<Layout><Jobs /></Layout>} />
          <Route path='/faqs' element={<Layout><Faqs /></Layout>} />
          <Route path='/blogs' element={<Layout><Blogs /></Layout>} />
          <Route path='/contact-us' element={<Layout><Contact /></Layout>} />
          {/* Other Routes End */}

          {/* Portal Routes */}

          {/* Dashboard */}
          <Route path='/user/dashboard' element={<DashboardLayout name="Dashboard"><Dashboard /></DashboardLayout>} />

          {/* Profile */}
          <Route exact path='/user/profile-setting' element={<DashboardLayout name="Profile Setting"><Profile /></DashboardLayout>} />

          {/* Jobs */}
          <Route path='/user/job/create' element={<DashboardLayout defaultActive="1" name="Create Job"><CreateJob /></DashboardLayout>} />
          <Route path='/user/job/history' element={<DashboardLayout defaultActive="1" name="Jobs History"><JobHistory /></DashboardLayout>} />
          <Route path='/user/job/applicants/:id' element={<DashboardLayout defaultActive="1" name="Jobs History"><Applicants /></DashboardLayout>} />
          <Route path='/user/job/finished' element={<DashboardLayout defaultActive="1" name="Complete Jobs History"><FinishedJobs /></DashboardLayout>} />
          <Route path='/user/job/applied' element={<DashboardLayout defaultActive="1" name="Applied Jobs"><AppliedJobs /></DashboardLayout>} />
          <Route path='/user/job/in-progress' element={<DashboardLayout defaultActive="1" name="Jobs In Progress"><JobsInProgress /></DashboardLayout>} />

          {/* Deposits */}
          <Route path='/user/deposit/now' element={<DashboardLayout defaultActive="2" name="Deposit Methods"></DashboardLayout>} />
          <Route path='/user/deposit/history' element={<DashboardLayout defaultActive="2" name="Deposit History"></DashboardLayout>} />


          {/* Withdraw */}
          <Route exact path='/user/withdraw/now' element={<DashboardLayout defaultActive="3" name="Withdraw Now"></DashboardLayout>} />
          <Route exact path='/user/withdraw/history' element={<DashboardLayout defaultActive="3" name="Withdraw History"></DashboardLayout>} />

          {/* Transactions */}
          <Route exact path='/user/transactions' element={<DashboardLayout name="Transactions"></DashboardLayout>} />

          {/* Change Password */}
          <Route exact path='/user/change-password' element={<DashboardLayout name="Change Password"></DashboardLayout>} />

          {/* Identity Verification */}
          <Route exact path='/user/identity-verification' element={<DashboardLayout name="Identity Verification"></DashboardLayout>} />

          <Route exact path='/user/:id' element={<DashboardLayout name="Dashboard"></DashboardLayout>} />
          <Route exact path='/user/:id/:id' element={<DashboardLayout name="Dashboard"></DashboardLayout>} />
          {/* Portal Routes End */}

        </Routes>
      </Router>
    </div>
  );
}

export default App;
