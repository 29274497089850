import React, { useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import JobCard from '../../Components/GetStarted/JobCard'

import GetStarted from '../../Components/GetStarted/GetStarted'

import { Cat1, Cat2, Cat3, Cat4, Cat5, Cat6, Cat7, Cat8, Person1, Person2, Person3, Person4, Person5, Person6, Person7, Person8 } from '../../assets/images'
import { useDispatch, useSelector } from 'react-redux'
import { getAllJobs } from '../../redux/jobSlice/actions'

let persons = [
    {
        img: Person1,
        bg: "#643D46"
    },
    {
        img: Person2,
        bg: "#C56320"
    },
    {
        img: Person3,
        bg: "#603D10"
    },
    {
        img: Person4,
        bg: "#501068"
    },
    {
        img: Person5,
        bg: "#126B8E"
    },
    {
        img: Person6,
        bg: "#1B8463"
    },
    {
        img: Person7,
        bg: "#730000"
    },
    {
        img: Person8,
        bg: "#006D6B"
    },
]

let categories = [
    {
        name: "Household Help",
        img: Cat1
    },
    {
        name: "Healthcare Appointment",
        img: Cat2
    },
    {
        name: "Salon/SPA Appointment",
        img: Cat3
    },
    {
        name: "Grocery Runs",
        img: Cat4
    },
    {
        name: "Travel Companions",
        img: Cat5
    },
    {
        name: "Personal Driver",
        img: Cat6
    },
    {
        name: "Lively-hood & Exercise",
        img: Cat7
    },
    {
        name: "Shopping",
        img: Cat8
    }
]

function Jobs() {
    let dispatch = useDispatch()
    let allJobs = useSelector((state) => state.job.allJobs)

    useEffect(() => {
        dispatch(getAllJobs()).then((res) => {
            if (res?.payload?.status === "success") {
                // console.log(res.payload)
            }
        })
    }, [])

    return (
        <div className='JobsMain'>
            <div className='banner-sec mb-2'>
                <div className='container d-flex align-items-center justify-content-center h-100'>
                    <div className='heading'>
                        <h1>All Jobs</h1>
                    </div>
                </div>
            </div>

            <div className='container pt-5 pb-5'>
                <Row>
                    <Col md={3}>
                        <div className='filterDiv shadow-lg card p-3'>
                            <div className='heading'>
                                <h5>Job Categories</h5>
                            </div>
                            <hr />
                            <div className='selectDiv'>
                                <div className="mb-3">
                                    <Form.Check
                                        type={"checkbox"}
                                        id={`1`}
                                        label={`All Category`}
                                    />
                                </div>

                                {categories.map((a, i) => (
                                    <div key={i} className="mb-3">
                                        <Form.Check
                                            type={"checkbox"}
                                            id={`checkbox-${i}`}
                                            label={`${a.name}`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Row className='row-gap-4'>
                            {allJobs?.map((a, i) => (
                                <Col md={4} key={i}>
                                    <JobCard job={a} obj={persons[Math.floor((Math.random() * 8))]} />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </div>
            <GetStarted />
        </div >
    )
}

export default Jobs