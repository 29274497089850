import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDetail, updateUserData, upload } from '../../../redux/authSlice/actions'

function Profile() {

  let dispatch = useDispatch()

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    address: '',
    state: '',
    zip: '',
    city: '',
    country: '',
    image: ''
  });

  let user = useSelector((state) => state.auth.user)

  const uploadImage = (e) => {
    let formData = new FormData()
    formData.append("image", e.target.files[0])

    dispatch(upload({ payload: formData })).then((res) => {
      if (res.payload?.status === "success") {
        let data = {image: res.payload?.data?.url}
        dispatch(updateUserData({ payload: data })).then((ress) => {
          if (ress.payload?.status === "success") {
            setUserData(data)
          }
        })
      }
    })
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  useEffect(() => {
    if (user) {
      console.log(user)
      setUserData({
        name: user.name,
        email: user.email,
        address: user.address,
        state: user.state,
        zip: user.zip,
        city: user.city,
        country: user.country,
        image: user.image
      })
    }
  }, [user])

  const updateUser = (e) => {
    e.preventDefault()
    dispatch(updateUserData({ payload: userData })).then((res) => {
      if (res.payload?.status === "success") {
        dispatch(getUserDetail())
      }
    })
  }

  return (
    <div className='profileMain shadow p-3'>
      <form onSubmit={(e) => updateUser(e)}>
        <Row>
          <Col md={4}>
            <div className='imgDiv' style={userData?.image ? { backgroundImage: `url(${userData.image})` } : {}}>
            </div>
            <div className='heading mt-3'>
              <h5 className='text-center'>{user?.name}</h5>
              <h6 className='text-center'>{user?.email}</h6>
            </div>
            <div className='btn-div'>
              <button type='button'>
                Upload Profile Picture
                <input onChange={(e) => uploadImage(e)} type='file' />
              </button>
            </div>
          </Col>
          <Col md={8}>
            <Row>
              <Col md={6}>
                <div className='inputDiv'>
                  <label>Name<sup>*</sup></label>
                  <input
                    type='text'
                    name="name"
                    value={userData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className='inputDiv'>
                  <label>Email Address</label>
                  <input type='email' disabled value={user?.email} />
                </div>
              </Col>
              <Col md={6}>
                <div className='inputDiv'>
                  <label>Address</label>
                  <input
                    type='text'
                    name="address"
                    value={userData.address}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className='inputDiv'>
                  <label>State</label>
                  <input
                    type='text'
                    name="state"
                    value={userData.state}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col md={4}>
                <div className='inputDiv'>
                  <label>Zip Code</label>
                  <input
                    type='text'
                    name="zip"
                    value={userData.zip}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className='inputDiv'>
                  <label>City</label>
                  <input
                    type='text'
                    name="city"
                    value={userData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className='inputDiv'>
                  <label>Country</label>
                  <input
                    type='text'
                    name="country"
                    value={userData.country}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='btn-div'>
          <button type='submit' className='primary'>Submit</button>
        </div>
      </form>
    </div>
  )
}

export default Profile